import { getRelationship } from "source/shared/getRelationship"
import { date } from "@churchcenter/datetime-fmt"

export default function calendarEventDetails({ data: event, included }) {
  const registrationUrlData = getRelationship(
    { data: event, included },
    "event_registration_url",
  )
  const locationData = getRelationship({ data: event, included }, "location")
  const campusData = (
    getRelationship({ data: event, included }, "campus_tags") || []
  ).map((campus) => campus.attributes.name)

  const campus =
    campusData.length == 0
      ? ""
      : campusData.length == 1
        ? campusData[0]
        : "Multiple Campuses"

  const eventConnections = getRelationship(
    { data: event, included },
    "event_connections",
  )

  const eventConnectionData = eventConnections?.filter(
    (connection) => connection.attributes.connected_to_type === "signup",
  )

  const eventSourceData = getRelationship(
    { data: event, included },
    "event_source",
  )

  const registrationUrlInfo = registrationUrlData
    ? {
        id: registrationUrlData.id,
        url: registrationUrlData.attributes.url,
        registrationsEvent: registrationUrlData.attributes.registrations_event,
        announcementOnly: registrationUrlData.attributes.announcement_only,
        canRegister: registrationUrlData.attributes.can_register,
      }
    : eventConnectionData.length
      ? {
          id: eventConnectionData[0].id,
          url: eventConnectionData[0].attributes.url,
          registrationsEvent: true,
          announcementOnly: false,
          canRegister: true, // If it's not from Registrations then assume it's open
        }
      : null

  const location = locationData
    ? {
        displayOnChurchCenter: locationData.attributes.display_on_church_center,
        id: locationData.id,
        name: locationData.attributes.name,
        formattedAddress: locationData.attributes.full_formatted_address,
        latitude: locationData.attributes.latitude,
        longitude: locationData.attributes.longitude,
        type: locationData.attributes.type,
        url: locationData.attributes.url,
        locationType:
          locationData.attributes.latitude &&
          locationData.attributes.longitude &&
          locationData.attributes.full_formatted_address === null
            ? "coords"
            : null,
      }
    : null

  const eventSource = eventSourceData
    ? {
        type: eventSourceData.attributes.source_type,
        id: eventSourceData.id,
        description: eventSourceData.attributes.source_description,
        linkText: eventSourceData.attributes.source_link_text,
        url: eventSourceData.attributes.source_url,
      }
    : null

  const {
    additional_dates_bookmarked,
    all_day_event,
    bookmarked,
    description,
    ends_at,
    image_url,
    initial_starts_at,
    multi_day_event,
    name,
    open_graph_description,
    open_graph_image_url,
    recurrence_description,
    starts_at,
    featured,
  } = event.attributes

  const formattedStartsAt = date(starts_at, {
    style: "abbreviated",
    year: true,
  })

  const formattedEndsAt = date(ends_at, { style: "abbreviated", year: true })

  const fallbackImageUrl = `/images/backgrounds/medium_${event.id % 9}.png`

  const openGraph = {
    description: open_graph_description,
    imageUrl: open_graph_image_url,
  }

  return {
    additionalDatesBookmarked: additional_dates_bookmarked,
    allDayEvent: !!all_day_event,
    bookmarked,
    campus,
    description,
    endsAt: ends_at,
    eventSource,
    id: event.id,
    imageUrl: image_url,
    fallbackImageUrl,
    formattedEndsAt,
    formattedStartsAt,
    initialStartsAt: initial_starts_at,
    location,
    multiDayEvent: !!multi_day_event,
    name,
    openGraph,
    recurrenceDescription: recurrence_description,
    recurring:
      recurrence_description && recurrence_description != "Does not repeat",
    registrationUrl: registrationUrlInfo,
    startsAt: starts_at,
    featured: featured,
  }
}
